import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from "./Header";
import Content from "./utility/Content.js";
function App() {
    return (
        <div>
            <Header/>
            <Content/>
        </div>
);
}

export default App;
